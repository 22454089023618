export const local =  {
    apiEndPoint:"https://m9n454aw8mvf.execute-api.us-east-1.amazonaws.com/dev/",
    env:"local",
    adalConfig: {
        tenant: 'LibertyMutual.onmicrosoft.com',
        tenantId: '08a844539-90e7-449bf-9075-957656561bf1',
        clientId: '77cf73d2-959a-4821-97c7-becb98f30758',// application id fro local env
        endpoints: {
            api: 'https://libertymutual.onmicrosoft.com//87dhfuf7-e0d6-422c-b8a8-41d22170cdac',
        },
        cacheLocation: 'localStorage',
    }
  };
